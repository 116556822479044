<template>
  <!-- profile css 사용 -->
  <div class="container">
    <icon
      src="img/membership/information.png"
      :width="`${$store.state.app.width}px`"
      :height="`${$store.state.app.width * 10.74}px`"
      :cover="false"
      :style="{ margin: '0 -3rem' }"
      />

    <footer-box
      :submit-text="'멤버십 가입하기 >'"
      :submit-cb="() => gotoMembershipJoin()"
      :submit-disabled="false"
    >
    </footer-box>

  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'

export default {
  name: 'MembershipInfomation',
  components: {
    Icon,
    FooterBox,
  },
  data() {
    return {}
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    artistData() {
      return this.$store.state.user.artistData
    },
    isMember() {
      if (this.artistData && Number(this.artistData.memberDate) > 0 && Number(this.artistData.memberDate) * 1000 > Date.now()) {
        return true
      }
      return false
    },
  },
  created() {
    if (this.isMember || this.artistData.billkey) {
      this.$router.replace('/membership')
      return
    }
  },
  methods: {
    gotoMembershipJoin() {
      this.$router.push('/membership/join')
    },
  },
}
</script>
